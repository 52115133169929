<template>
  <div>
    <v-app-bar
      class="nav-appbar"
      color="white"
      height="52"
      light
      elevation="0"
      clipped-left>
      <div class="navbar-wrapper">
        <a
          :href="shipnity"
          class="nav-item nav-item-link">
          Shipnity
        </a>
        <a
          :href="shipnity"
          class="nav-item nav-item-link d-none d-sm-none d-md-block">
          Live
        </a>
        <a
          :href="domain"
          class="nav-item nav-item-link d-none d-sm-none d-md-block">
          Inbox
        </a>
        <a
          :href="`${shipnityUrl}/orders`"
          class="nav-item nav-item-link d-none d-sm-none d-md-block">
          Order
        </a>
        <v-spacer />
        <v-btn
          class="nav-item"
          tag="a"
          icon
          @click="toggleBrightness()">
          <img
            v-if="isBrightness === 0 || isBrightness === true"
            :src="iconSunBlue" />
          <img
            v-else-if="isBrightness === 1"
            :src="iconMoonBig" />
          <img
            v-else-if="isBrightness === 2 || isBrightness === false"
            :src="iconMoonSmall" />
        </v-btn>
        <v-btn
          class="nav-item d-none d-sm-none d-md-flex"
          tag="div"
          text>
          {{ username }}
        </v-btn>
        <div class="nav-item-button d-none d-sm-none d-md-flex">
          <v-btn
            elevation="0"
            class="nav-item"
            tag="button"
            rel="nofollow"
            @click="logout()">
            Logout
          </v-btn>
        </div>
      </div>
      <div class="hamberger d-md-none d-sm-block">
        <v-app-bar-nav-icon @click="drawer = !drawer" />
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
      v-model="drawer"
      hide-overlay
      clipped
      app>
      <v-sheet
        color="grey lighten-4"
        class="pa-4">
        <div>{{ username }}</div>
      </v-sheet>
      <v-divider></v-divider>
      <v-list shaped>
        <v-list-item
          :href="shipnity"
          link>
          <v-list-item-content>
            <v-list-item-title>Live</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :href="domain"
          link>
          <v-list-item-content>
            <v-list-item-title>Inbox</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :href="`${shipnityUrl}/orders`"
          link>
          <v-list-item-content>
            <v-list-item-title>Order</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-2" />
        <v-list-item @click="logout()">
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AuthProvider from '@/resources/AuthProvider'

export default {
  name: 'Navbar',
  data () {
    return {
      shipnityUrl: process.env.VUE_APP_SHIPNITY,
      drawer: false,
      shipnity: process.env.VUE_APP_WHALEBOX,
      domain: process.env.VUE_APP_DOMAIN,
      iconSunBlue: require('@/assets/svg/Moon/sun_blue.svg'),
      iconMoonSmall: require('@/assets/svg/Moon/moonsmall_blue.svg'),
      iconMoonBig: require('@/assets/svg/Moon/moonbig_blue.svg')

    }
  },
  computed: {
    ...mapGetters({
      isBrightness: 'Theme/isBrightness',
      username: 'Auth/username'
    })
  },
  mounted () {

  },
  methods: {
    ...mapActions({
      toggleBrightness: 'Theme/toggleBrightness'
    }),
    async logout () {
      AuthProvider.logout().catch(() => {
        window.location.href = this.shipnity
      })
    }
  }
}
</script>

<style>
  .navbar-wrapper {
    display: flex;
    width: 100%;
  }
  .navbar-wrapper .nav-item {
    height: 36px !important;
    margin-top: 2px;
    margin-bottom: 2px;
    text-decoration: none;
    color: #4a4a4a !important;
    font-size: 16px !important;
    line-height: 32px;
    letter-spacing: 0 !important;
    padding: 8px 12px !important;
    text-transform: none !important;
    align-self: center;
    text-transform: none;
  }
  .navbar-wrapper a.nav-item {
    height: 48px !important;
    letter-spacing: .3px;
    font-weight: 500;
  }
  .navbar-wrapper button.nav-item {
    margin-left: 5px;
    margin-right: 5px;
    letter-spacing: .3px;
    font-weight: 500;
  }
  .v-application .nav-appbar.white{
    border-bottom: 1px solid #f5f5f5 !important;
  }
  .v-toolbar__content {
    padding: 0 !important;
    align-items: stretch !important;
  }
  .nav-item.nav-item-link:hover {
    background-color: #fafafa !important;
    color: #25a9dc !important;
  }
  .nav-title {
    margin: 0 12px 0;
  }
  .nav-img-title {
    margin: 0 6px 0;
  }
</style>
