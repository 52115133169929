<template>
  <div class="chat-card">
    <v-virtual-scroll
      ref="VirtualChatList"
      :items="filteredChats"
      :item-height="itemHeight"
      :height="height"
      @scroll.native="onScroll">
      <template v-slot="{ item }">
        <div
          :key="`chat-list-${item.id}`"
          :class="`${item.id == $route.params.customerId ? 'chat-active' : ''}`">
          <user-avatar
            v-if="mobile"
            :item="item"
            :access-token="page.accessToken"
            dots />
          <message-card
            v-else
            :ref="`chat-list-${item.id}`"
            :item="item"
            :access-token="page.accessToken"
            :small="small"
          />
        </div>
      </template>
    </v-virtual-scroll>
    <div class="text-center customer-loading">
      <v-progress-circular
        v-if="isChatLoading"
        indeterminate
        width="2"
        size="20"
        color="primary" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import ChatProvider from '@/resources/ChatProvider'
import UserAvatar from '@/components/Message/UserAvatar.vue'
import MessageCard from './MessageCard.vue'

export default {
  name: 'ChatList',
  components: {
    MessageCard,
    UserAvatar
  },
  props: {
    mobile: {
      type: Boolean,
      default: false
    },
    heightOffset: {
      type: String,
      default: '265px'
    }
  },
  data () {
    return {
      chatCardComponent: MessageCard,
      next: null,
      isCompleted: false,
      scrollBottom: 0,
      scrollHeight: 0,
      cancelToken: undefined
    }
  },
  computed: {
    ...mapGetters({
      page: 'Chat/page',
      pageId: 'Chat/pageId',
      platform: 'Chat/platform',
      customerList: 'Chat/customerList',
      customers: 'Chat/customers',
      stageId: 'Chat/stageId',
      filterId: 'Chat/filterId',
      searchChat: 'Chat/searchChat',
      isCustomerListAlert: 'Chat/isCustomerListAlert',
      firstCustomerList: 'Chat/firstCustomerList',
      sizeChatList: 'Theme/sizeChatList',
      isChatLoading: 'Chat/isChatLoading'
    }),
    small () {
      return this.sizeChatList === 'small'
    },
    itemHeight () {
      const small = this.small ? '72px' : '116px'
      return this.mobile ? '56px' : small
    },
    height () {
      return this.mobile ? 'calc(100vh - 110px - 52px)' : `calc(100vh - ${this.heightOffset} - 52px)`
    },
    filteredChats () {
      let results = []
      const pageId = this.$route.params.pageId
      const customerId = this.$route.params.customerId
      const filterId = this.filterId
      const customerListObj = this.customerList
      const customers = this.customers
      const customerList = Object.keys(customerListObj).reverse()
      const isCustomerListAlert = this.isCustomerListAlert
      const firstCustomerList = this.firstCustomerList

      for (let i = 0; i < customerList.length; i++) {
          const key = customerList[i]
          const customer = customers[key]
          if (customer) {
            results.push(customer)
            if (!isCustomerListAlert && key === firstCustomerList) {
              break
            }
          }
      }

      results = results.reverse()

      if (pageId) {
        results = results.filter((item) => item.id === customerId)
      } else if (filterId === 1) {
        results = results.filter((item) => item.unreadCount)
      }

      return results
    }
  },
  watch: {
    async pageId (value) {
      this.setCustomerList({})
      await this.loadAsyncData(value)
      this.loadAsyncData(value, false, 50)
    },
    async platform () {
      const pageId = this.pageId
      this.setCustomerList({})
      await this.loadAsyncData(pageId)
      this.loadAsyncData(pageId, false, 50)
    },
    async stageId () {
      const pageId = this.pageId
      this.setCustomerList({})
      await this.loadAsyncData(pageId)
      this.loadAsyncData(pageId, false, 50)
    },
    async searchChat () {
      const pageId = this.pageId
      await this.setCustomerList({})
      this.loadAsyncData(pageId, false, 50)
    }
  },
  async mounted () {
    const pageId = this.pageId
    const customerId = this.$route.params.customerId
    await this.loadAsyncData(pageId)
    await this.loadAsyncData(pageId, false, 50)
    // alert current selected customer
    if (customerId) {
      this.alertCustomerList({
        id: customerId
      })
    }
    this.loadAsyncData(pageId, true, 1000)
  },
  methods: {
    ...mapActions({
      setCustomer: 'Chat/setCustomer',
      setCustomers: 'Chat/setCustomers',
      setCustomerList: 'Chat/setCustomerList',
      alertCustomerList: 'Chat/alertCustomerList',
      setIsChatLoading: 'Chat/setIsChatLoading'
    }),
    onScroll (event) {
      const pageId = this.pageId
      const element = event.currentTarget || event.target
      this.currentScrollTop = element.scrollTop
      const current = element.scrollHeight - element.scrollTop
      if (element && current < element.clientHeight + 10) {
        this.loadAsyncData(pageId)
      }
      this.$nextTick(() => {
        this.scrollHeight = this.$refs.VirtualChatList.$el.scrollHeight
      })
    },
    async loadAsyncData (pageId, isPreload = false, perPage = 11) {
      const platform = this.platform
      if (typeof this.cancelToken !== typeof undefined) {
        this.cancelToken.cancel('canceled')
      }
      this.cancelToken = axios.CancelToken.source()
      const customers = this.customers
      const stageId = this.stageId
      const searchChat = this.searchChat
      let customerList = this.customerList
      const chatKeyArray = Object.keys(customerList)

      let next = null
      if (chatKeyArray.length) {
        const lastCustomer = chatKeyArray[chatKeyArray.length - 1]
        next = customers[lastCustomer].cursors
        // if (!next && stageId === 0) {
        //   next = (chatKeyArray.length / 200) + 2
        // }
      }
      this.setIsChatLoading(!isPreload)
      // changes: getChats
      // reverse participants to senders
      // old data
      // {
      //   "id": "t_2736861516333783",
      //   "senders": {
      //       "data": [
      //           {
      //               "name": "Posathorn Atiyutpakpol",
      //               "email": "2117825224990565@facebook.com",
      //               "id": "2117825224990565"
      //           },
      //           {
      //               "name": "Whale Whale",
      //               "email": "115849176470304@facebook.com",
      //               "id": "115849176470304"
      //           }
      //       ]
      //   },
      //   "unreadCount": 0,
      //   "snippet": "Okay",
      //   "updatedTime": "2021-08-08T08:25:21+0000",
      //   "messages": {
      //       "data": [
      //           {
      //               "from": {
      //                   "name": "Posathorn Atiyutpakpol",
      //                   "email": "2117825224990565@facebook.com",
      //                   "id": "2117825224990565"
      //               },
      //               "id": "m_RbOHu0ZVOwint6J_uWkpStfru34SFJCqAdZ9-XlJWwy0gzCHjT3Y3hnSkwQSREpQQGJqOhfbBVITFapxtbSa8g"
      //           }
      //       ],
      //       "paging": {
      //           "cursors": {
      //               "before": "QVFIUmlFYzQtNk5iRlB1YzBPaE9Uc1pUSDB2dE15ZAU04MUx4UmdKUkNDM3F0d1hjcGlLZA2lUWGVxdk5VWmQxZA18zQ3E4dDhQdEpKeW1kLUY2TFJGT2Jpc3ZAtc3JuYnZA0SUdGY05WblkzaDlwbVBQT3ZA4aHdkbjN1STZAVYVJRaEdVVHVi",
      //               "after": "QVFIUmlFYzQtNk5iRlB1YzBPaE9Uc1pUSDB2dE15ZAU04MUx4UmdKUkNDM3F0d1hjcGlLZA2lUWGVxdk5VWmQxZA18zQ3E4dDhQdEpKeW1kLUY2TFJGT2Jpc3ZAtc3JuYnZA0SUdGY05WblkzaDlwbVBQT3ZA4aHdkbjN1STZAVYVJRaEdVVHVi"
      //           },
      //           "next": "https://graph.facebook.com/v9.0/t_2736861516333783/messages?access_token=EAAa6eWPv06MBADxyL7d1luZCovLqu5BmOewhmRKZAIQB1hgbt7IU9ssU6jkFdRr4ndngZArJlwRZBe8PmgYos72KVjpspJwubgvwZAjpH3J93wsh4XPuzhx8w03yadOdvZBevQLJivbMusW9Q2U6Bg7lQanH2hj8jepYvvt5S1Pzqn86qOWuiX&fields=from&limit=1&after=QVFIUmlFYzQtNk5iRlB1YzBPaE9Uc1pUSDB2dE15ZAU04MUx4UmdKUkNDM3F0d1hjcGlLZA2lUWGVxdk5VWmQxZA18zQ3E4dDhQdEpKeW1kLUY2TFJGT2Jpc3ZAtc3JuYnZA0SUdGY05WblkzaDlwbVBQT3ZA4aHdkbjN1STZAVYVJRaEdVVHVi"
      //       }
      //   }
      // }
      const response = await ChatProvider.getChats({ pageId }, {
        platform,
        search: searchChat,
        status: stageId,
        perPage,
        next
      }, { cancelToken: this.cancelToken.token })
      if (response) {
        this.isCompleted = !response.results.length
        const customerObj = {}
        const chatbox = {}
        const threads = []
        for (let i = 0; i < response.results.length; i++) {
          const chat = response.results[i]
          if (platform === 'instagram' && chat.participants) {
            chat.senders = {
              data: chat.participants.data.map(({ username, userId, id }) => ({
                id,
                userId,
                name: username,
                email: ''
              })).reverse()
            }
            chat.snippet = this.$_.truncate(chat.messages?.data[0]?.message, { length: 24 })
            chat.messages = {
              ...chat.messages,
              data: chat?.messages?.data.map(({ id, from, message }) => ({
                id,
                message,
                from: {
                  id: from.id,
                  name: from.username,
                  email: ''
                }
              }))
            }
          }
          const facebookId = chat.senders.data[0].id
          let cursors = null
          if (chat.messages && chat.messages.paging) {
            // cursors = chat.messages.paging.cursors.after
            cursors = response.nextPage
          }
          chatbox[facebookId] = {
            id: facebookId
          }
          threads.push(chat.id)
          customerObj[facebookId] = {
            id: facebookId,
            threadId: chat.id,
            instagramUserId: chat.senders.data[0].userId,
            name: chat.senders.data[0].name,
            beforeUnreadCount: chat.unreadCount,
            updatedTime: chat.updatedTime,
            unreadCount: 0,
            to: `/messages/${chat.senders.data[0].id}`,
            cursors
          }
          if (chat.snippet) customerObj[facebookId].snippet = chat.snippet
          this.setCustomer(customerObj[facebookId])
        }

        const shipInfo = await ChatProvider.getShipnityInfo({ pageId }, {
          chats: threads
        }, { cancelToken: this.cancelToken.token }).finally(() => {
          this.setIsChatLoading(false)
        })
        if (shipInfo) {
          for (let i = 0; i < shipInfo.result.length; i++) {
            const {
              facebookId, facebookReadAt, tags, tagsWithColor,
              rewardLink, orderStatus, optedIn
              // orderBank, paymentAccepted
            } = shipInfo.result[i]
            if (customerObj[facebookId]) {
              // console.log('orderBank, paymentAccepted', orderBank, paymentAccepted)
              customerObj[facebookId].orderStatus = orderStatus
              // customerObj[facebookId].orderBank = orderBank
              // customerObj[facebookId].paymentAccepted = paymentAccepted
              customerObj[facebookId].tags = tags
              customerObj[facebookId].tagsWithColor = tagsWithColor
              customerObj[facebookId].readAt = facebookReadAt
              customerObj[facebookId].rewardLink = rewardLink
              customerObj[facebookId].optedIn = optedIn
              const readAt = this.$dayjs(customerObj[facebookId].readAt)
              const updateTime = this.$dayjs(new Date(customerObj[facebookId].updatedTime))
              if (!readAt.date() || readAt.isBefore(updateTime)) {
                customerObj[facebookId].unreadCount = customerObj[facebookId].beforeUnreadCount
              }
              this.setCustomer(customerObj[facebookId])
            }
          }
          if (!isPreload) {
            customerList = !next ? chatbox : { ...customerList, ...chatbox }
            this.setCustomerList(customerList)
          }
          this.next = next
        }
      }
    }
  }
}
</script>

<style scope>
  .customer-loading {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin-top: 4px;
    height: 38px;
  }
  .chat-card {
    position: relative;
  }
  .chat-active .chat-card::before {
    background: currentColor;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.08;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.2s opacity;
  }

  .chat-active .chat-badge::before {
    background: currentColor;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.08;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.2s opacity;
  }
</style>
