<template>
  <v-tabs
    :value="stageId"
    class="stage-list mt-3"
    show-arrows
    grow
    @change="setStageID($event)">
    <v-tab class="flex-column">
      <img :src="stageId === 0 ? allActive : allDefault" />
      <div class="chat-badge">
        ทั้งหมด
      </div>
    </v-tab>
    <v-tab class="flex-column">
      <img :src="stageId === 1 ? orderActive : orderDefault" />
      <div class="chat-badge">
        สั่งจอง
      </div>
    </v-tab>
    <v-tab class="flex-column">
      <img :src="stageId === 2 ? printActive : printDefault" />
      <div class="chat-badge">
        รอพิมพ์
      </div>
    </v-tab>
    <v-tab class="flex-column">
      <img :src="stageId === 3 ? packActive : packDefault" />
      <div class="chat-badge">
        รอแพ็ก
      </div>
    </v-tab>
    <v-tab class="flex-column">
      <img :src="stageId === 4 ? deliveredActive : deliveredDefault" />
      <div class="chat-badge">
        ส่งแล้ว
      </div>
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'StageList',
  data () {
    return {
      allActive: require('@/assets/svg/TabBarLeft/AllActive.svg'),
      allDefault: require('@/assets/svg/TabBarLeft/AllDefault.svg'),
      deliveredActive: require('@/assets/svg/TabBarLeft/DeliveredActive.svg'),
      deliveredDefault: require('@/assets/svg/TabBarLeft/DeliveredDefault.svg'),
      orderActive: require('@/assets/svg/TabBarLeft/OrderActive.svg'),
      orderDefault: require('@/assets/svg/TabBarLeft/OrderDefault.svg'),
      packActive: require('@/assets/svg/TabBarLeft/PackActive.svg'),
      packDefault: require('@/assets/svg/TabBarLeft/PackDefault.svg'),
      printActive: require('@/assets/svg/TabBarLeft/PrintActive.svg'),
      printDefault: require('@/assets/svg/TabBarLeft/PrintDefault.svg')
    }
  },
  computed: {
    ...mapGetters({
      pageId: 'Chat/pageId',
      stageId: 'Chat/stageId'
    })
  },
  methods: {
    ...mapActions({
      setStageID: 'Chat/setStageID'
    })
  }
}

</script>

<style>
  .v-slide-group__next,
  .v-slide-group__prev {
    display: none !important;
  }
  .stage-list .v-tab {
    min-width: 0px;
    padding: 0px;
  }
  .stage-list .v-tabs-bar {
    height: 54px;
  }
  .stage-list .v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
    color: #8a8a8a !important;
  }
  .chat-badge .v-badge__badge {
    left: calc(100% - 8px) !important;
  }
</style>
