<template>
  <v-card
    v-bind="$attrs"
    :height="height"
    :to="item.to"
    :class="{ 'chat-card-small': small }"
    class="chat-card"
    tile>
    <div class="d-flex flex-no-wrap align-start justify-space-around">
      <user-avatar
        :item="item"
        :access-token="accessToken" />
      <div class="chat-info">
        <div class="text--book message-name">
          <div> {{ item.name }}</div>
          <!-- <v-spacer />
          <div
            v-if="small"
            class="caption-time text--book">
            <span v-if="isDateNow">{{ item.updatedTime | formatTimeOnly }}น.</span>
            <span v-else>{{ item.updatedTime | formatTime }}น.</span>
          </div> -->
        </div>
        <div class="d-flex justify-space-between">
          <p
            id="snippet"
            class="text--book caption">
            {{ item.snippet }}
          </p>
          <div class="administrator-facepile-mini">
            <v-avatar
              v-if="getAdministrators.length"
              class="member"
              size="24"
              color="indigo">
              <v-img
                :src="getAdministrators[0].avatar"
                :alt="getAdministrators[0].username"
              />
            </v-avatar>
            <v-avatar
              v-if="getAdministrators.length > 1"
              class="member"
              size="24"
              color="info">
              <span class="white--text">+{{ getAdministrators.length - 1 }}</span>
            </v-avatar>
          </div>
        </div>
        <div
          v-if="!small"
          class="chat-tags">
          <v-chip
            v-for="tag in item.tagsWithColor"
            :key="tag[0]"
            class="mb-2 mr-2"
            :color="tag[1]"
            text-color="white"
            small
            label>
            {{ tag[0] }}
          </v-chip>
          <div
            v-if="item.tags.length < 1"
            style="height: 25px;">
          </div>
        </div>
        <div
          v-if="!small"
          class="d-flex justify-space-between mt-2">
          <span class="caption text--book">
            <span class="font-weight-medium text--book">สถานะออเดอร์: </span>
            <i
              v-if="getOrderStatusText !== '-'"
              class="dots text--book">
            </i>
            {{ getOrderStatusText }}
          </span>
          <span class="caption-time text--book">
            <span v-if="isDateNow">{{ item.updatedTime | formatTimeOnly }}น.</span>
            <span v-else>{{ item.updatedTime | formatTime }}น.</span>
          </span>
        </div>
        <div
          v-else
          class="d-flex justify-space-between">
          <span class="caption-time text--book">
            <span class="font-weight-medium text--book">สถานะออเดอร์: </span>
            <i
              v-if="getOrderStatusText !== '-'"
              class="dots text--book">
            </i>
            {{ getOrderStatusText }}
          </span>
          <v-spacer />
          <span class="caption-time text--book">
            <span v-if="isDateNow">{{ item.updatedTime | formatTimeOnly }}น.</span>
            <span v-else>{{ item.updatedTime | formatTime }}น.</span>
          </span>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import UserAvatar from '@/components/Message/UserAvatar.vue'

export default {
  name: 'MessageCard',
  components: {
    UserAvatar
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    small: {
      type: Boolean,
      default: false
    },
    accessToken: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      customers: 'Chat/customers',
      administrators: 'Chat/administrators'
    }),
    height () {
      return this.small ? '72px' : '116px'
    },
    isDateNow () {
      return this.$dayjs().isSame(this.item.updatedTime, 'day')
    },
    getAdministrators () {
      const administrators = Object.keys(this.administrators).map((key) => this.administrators[key])
      return Array.isArray(administrators) ? administrators.filter(
        (item) => item.action === 'subscribe' && item.chatId === this.item.threadId
      ) : []
    },
    getOrderStatusText () {
      const customer = this.customers[this.item.id] || {}
      switch (customer.orderStatus) {
        case 0: return 'สั่งจอง'
        case 1: return 'รอพิมพ์'
        case 2: return 'รอแพ็ค'
        case 3: return 'ส่งแล้ว'
        case 4: return 'ตรวจสอบหลักฐาน'
        default: return '-'
      }
    }
  }
}
</script>

<style scoped>
  #snippet {
    width: 150px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .message-name {
    height: 24px;
    word-break: break-all;
    overflow: hidden;
  }
  .chat-tags {
    height: 24px;
    overflow: hidden;
  }

  .chat-card p {
    margin-bottom: 4px;
  }

  .chat-card.chat-card-small {
    padding: 2px 8px;
  }
  .chat-card {
    padding: 8px;
  }
  .user-avatar-badge {
    position: relative;
    padding: 0;
    margin: 8px 14px 0 0;
  }
  .active .user-avatar-badge::before {
    background: transparent;
  }
  .chat-info {
    position: relative;
    width: 100%;
  }
  .count-msg {
    position: absolute;
    top: -8px;
    right: -15px;
    padding: 8px 10px;
    z-index: 1;
  }

  .dots {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #0d968c;
  }
  .caption-time {
    font-size: .7rem !important;
  }
  .administrator-facepile-mini {
    min-width: 30px;
    cursor: default;
    float: left;
    margin-left: 18px;
    overflow: hidden;
    padding: 2px 0 0 18px;
  }

  .administrator-facepile-mini .member {
      height: 24px;
      width: 24px;
      margin: 0 0 0 -18px;
      float: left;
      z-index: 1;
  }

  .member span {
      line-height: inherit;
  }
</style>
