<template>
  <div class="d-flex justify-space-between">
    <v-menu>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          depressed
          color="success"
          v-bind="attrs"
          v-on="on">
          <v-icon small>
            mdi-filter
          </v-icon>
          <span v-if="filterId === 1">
            ข้อความที่ยังไม่ได้อ่าน
          </span>
          <span v-else-if="filterId === 0">
            ข้อความทั้งหมด
          </span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          :class="filterId === 0 ? 'success' : ''"
          @click="setFilterID(0)">
          <v-list-item-title>
            <v-icon
              :color="filterId === 0 ? '#ffffff' : ''"
              small>
              mdi-forum
            </v-icon>
            <span :class="filterId === 0 ? 'white--text' : ''">
              ข้อความทั้งหมด
            </span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :class="filterId === 1 ? 'success' : ''"
          @click="setFilterID(1)">
          <v-list-item-title>
            <v-icon
              :color="filterId === 1 ? '#ffffff' : ''"
              small>
              mdi-email
            </v-icon>
            <span :class="filterId === 1 ? 'white--text' : ''">
              ข้อความที่ยังไม่ได้อ่าน
            </span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      :color="isCustomerListAlert ? 'error' : 'success'"
      :outlined="isCustomerListAlert ? true: false"
      :depressed="isCustomerListAlert ? false : true"
      class="ml-2"
      small
      @click="changeStateAlert(!isCustomerListAlert)">
      <div v-if="isCustomerListAlert">
        <v-icon small>
          mdi-pause
        </v-icon>
        <span> พักข้อความ </span>
      </div>
      <div v-else>
        <v-icon small>
          mdi-play
        </v-icon>
        <span> เริ่มรับข้อความ </span>
      </div>
    </v-btn>
    <v-spacer />
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          color="#6c6c6c"
          small
          depressed
          text
          v-bind="attrs"
          v-on="on"
          @click="changeSizeChat($event)">
          <v-icon small>
            {{ iconSizeChatList }}
          </v-icon>
        </v-btn>
      </template>
      <div>
        ย่อ/ขยาย
      </div>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FilterChat',
  computed: {
    ...mapGetters({
      filterId: 'Chat/filterId',
      isCustomerListAlert: 'Chat/isCustomerListAlert',
      sizeChatList: 'Theme/sizeChatList'
    }),
    iconSizeChatList () {
      return this.sizeChatList === 'default' ? 'mdi-window-restore' : 'mdi-window-maximize'
    }
  },
  methods: {
    ...mapActions({
      setFilterID: 'Chat/setFilterID',
      setIsCustomerListAlert: 'Chat/setIsCustomerListAlert',
      setSizeChatList: 'Theme/setSizeChatList'
    }),
    changeStateAlert (val) {
      this.setIsCustomerListAlert(val)
    },
    changeSizeChat () {
      const size = this.sizeChatList === 'default' ? 'small' : 'default'
      this.setSizeChatList(size)
    }
  }
}

</script>

<style scoped>

</style>
