<template>
  <v-autocomplete
    :search-input.sync="search"
    :items="tags"
    class="mt-3"
    placeholder="ค้นหา..."
    append-icon=""
    prepend-inner-icon="mdi-magnify"
    hide-details
    outlined
    dense
    clearable
    hide-no-data
    auto-select-first
    @update:search-input="setSearch($event)"
    @keyup.enter="enterSearch($event)"
    @change="changeSearch($event)">
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'SearchChat',
  data () {
    return {
      search: '',
      tags: []
    }
  },
  computed: {
    ...mapGetters({
      customers: 'Chat/customers',
      searchChat: 'Chat/searchChat',
      shopInfo: 'Chat/shopInfo'
    }),
    getCustomers () {
      return Object.values(this.customers).map((item) => item.name)
    },
    getAllowedTags () {
      return this.shopInfo.shop ? this.shopInfo.shop.customerTags : []
    }
  },
  methods: {
    ...mapActions({
      setSearchChat: 'Chat/setSearchChat'
    }),
    enterSearch () {
      this.setSearchChat(this.search)
    },
    changeSearch (e) {
      this.setSearchChat(e)
    },
    // eslint-disable-next-line func-names
    setSearch: _.debounce(function (e) {
      if (e && e.length > 1) {
        this.tags = [...this.getAllowedTags, ...this.getCustomers]
      } else {
        this.tags = []
      }
      if (e !== null) this.changeSearch(e)
    }, 800)
  }
}

</script>

<style scoped>

</style>
