<template>
  <v-sheet
    v-show="drawer"
    width="100%"
    clipped
    permanent
    stateless
    app>
    <v-skeleton-loader
      :loading="false"
      type="image, card-heading, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line"
    >
      <div class="chat-list-wrap w-sm-fullscreen">
        <page-list
          :items="pages" />
        <stage-list />
        <search-chat />
        <filter-chat class="my-3" />
        <chat-list
          v-if="pageId !== null"
          height-offset="265px" />
      </div>
    </v-skeleton-loader>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'
import PageList from '@/components/Message/PageList.vue'
import StageList from '@/components/Message/StageList.vue'
import SearchChat from '@/components/Message/SearchChat.vue'
import FilterChat from '@/components/Message/FilterChat.vue'
import ChatList from './ChatList.vue'

export default {
  name: 'Navbar',
  components: {
    PageList,
    StageList,
    SearchChat,
    FilterChat,
    ChatList
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters({
      pageId: 'Chat/pageId',
      pages: 'Chat/pages',
      page: 'Chat/page'
    }),
    drawer () {
      return this.$route.params.customerId === undefined
    }
  }
}
</script>

<style>
  .chat-list-wrap {
    padding: 20px 12px 12px 12px;
  }
</style>
