<template>
  <div id="mini-sidebar">
    <v-sheet
      :width="widthSidebar">
      <template v-if="drawer">
        <v-skeleton-loader
          :loading="false"
          type="image, card-heading, list-item-avatar-three-line,
            list-item-avatar-three-line, list-item-avatar-three-line,
            list-item-avatar-three-line">
          <div class="chat-list-wrap w-sm-fullscreen">
            <div class="d-flex">
              <v-icon
                class="mr-3"
                @click="setDrawer(false)">
                mdi-chevron-left
              </v-icon>
              <page-list :items="pages" />
            </div>
            <stage-list />
            <search-chat />
            <filter-chat class="my-3" />
            <chat-list
              v-if="pageId !== null"
              height-offset="270px" />
          </div>
        </v-skeleton-loader>
      </template>
      <template v-else>
        <v-avatar
          class="d-block text-center mx-auto mt-4 toggle-navbar"
          color="grey darken-1"
          size="42"
          @click="setDrawer(!drawer)"
        >
          <img
            v-show="pageId"
            class="elevation-6"
            :src="`https://graph.facebook.com/${page.uid}/picture?type=small&access_token=${page.accessToken}`"
            :alt="page.name"
          >
        </v-avatar>
        <v-divider class="mx-3 mt-5 mb-3"></v-divider>
        <chat-list
          v-if="pageId !== null"
          mobile />
      </template>
    </v-sheet>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PageList from '@/components/Message/PageList.vue'
import StageList from '@/components/Message/StageList.vue'
import SearchChat from '@/components/Message/SearchChat.vue'
import FilterChat from '@/components/Message/FilterChat.vue'
import ChatList from './ChatList.vue'

export default {
  name: 'Navbar',
  components: {
    PageList,
    StageList,
    SearchChat,
    FilterChat,
    ChatList
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      drawer: 'Notification/drawerInbox',
      pageId: 'Chat/pageId',
      pages: 'Chat/pages',
      page: 'Chat/page'
    }),
    widthSidebar () {
      return this.drawer ? '385px' : '80px'
    }
  },
  methods: {
    ...mapActions({
      setDrawer: 'Notification/setDrawerInbox'
    })
  }
}
</script>

<style>
  #mini-sidebar .chat-active .user-avatar-badge {
    background: #eeeeee;
  }
  .toggle-navbar {
    cursor: pointer;
  }
  .chat-list-wrap {
    padding: 20px 12px 12px 12px;
  }
  .dialog-navbar.v-dialog {
    max-height: 100% !important;
    align-self: start;
    border-radius: 0;
    flex: 1 0 auto;
    margin: 0;
    overflow: visible;
  }
</style>
