<template>
  <div class="modern-scrollbar">
    <navbar />
    <div class="d-flex">
      <sidebar v-if="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl" />
      <mini-sidebar v-else-if="$vuetify.breakpoint.md" />
      <full-sidebar v-else />
      <div class="d-flex flex-grow-1">
        <div
          :class="{ 'hide-sm': hideSm }"
          class="chat-main-wrap w-sm-fullscreen">
          <div
            v-if="isHome"
            class="chat-empty d-flex flex-column justify-center align-center">
            <img
              :src="emptyChat"
              alt="emptyChat">
            <p class="chat-empty-text">
              กรุณาเลือกลูกค้าที่ต้องการติตต่อ
            </p>
          </div>
          <router-view
            v-else
            :key="$route.fullPath" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import humps from 'humps'
import { v4 as uuidv4 } from 'uuid'
import { mapActions, mapGetters } from 'vuex'
import Navbar from '@/components/Layout/Navbar.vue'
import Sidebar from '@/views/inbox/components/Sidebar.vue'
import MiniSidebar from '@/views/inbox/components/MiniSidebar.vue'
import FullSidebar from '@/views/inbox/components/FullSidebar.vue'
import ChatProvider from '@/resources/ChatProvider'

export default {
  name: 'Inbox',
  components: {
    Navbar,
    Sidebar,
    MiniSidebar,
    FullSidebar
  },
  channels: {
    ConversationChannel: {
      connected () {
      },
      rejected () {},
      async received (data) {
        const customers = this.customers
        const pageId = this.pageId
        const myData = humps.camelizeKeys(data)
        if (!customers[myData.for]) {
          await this.getThread(pageId, myData.for)
        }
        // เด้งเฉพาะข้อความใหม่เฉพาะ tab ทั้งหมด
        // มีในรายชื่อที่แสดง
        // เมื่อเปิดหน้าต่างแชทลูกค้าคนนั้น
        // const customerId = this.$route.params.customerId
        // if (this.stageId === 0 || customerId === myData.for) {
          this.addNewMessage({
            ...myData,
            status: 'read',
            id: myData.tid || uuidv4()
          })
        // }
      },
      disconnected () {}
    },
    ChatRoomChannel: {
      connected () {
      },
      rejected () {},
      received (data) {
        const myData = humps.camelizeKeys(data)
        this.setAdministrator({
          id: myData.userId,
          ...myData
        })
      },
      disconnected () {}
    }
  },
  data () {
    return {
      emptyChat: require('@/assets/svg/EmptyChat.svg')
    }
  },
  computed: {
    ...mapGetters({
      token: 'Auth/token',
      userId: 'Auth/userId',
      page: 'Chat/page',
      pageId: 'Chat/pageId',
      platform: 'Chat/platform',
      customers: 'Chat/customers'
    }),
    isHome () {
      return this.$route.name === 'Inbox'
    },
    hideSm () {
      return this.$route.params.customerId === undefined
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.name === 'Messages') {
      window.location.href = '/'
    } else {
      next()
    }
  },
  methods: {
    ...mapActions({
      setPage: 'Chat/setPage',
      setShopInfo: 'Chat/setShopInfo',
      setCustomer: 'Chat/setCustomer',
      setAdministrator: 'Chat/setAdministrator',
      addNewMessage: 'Chat/addNewMessage'
    }),
    async getThread (pageId, customerId) {
      // changes: findConversation
      // reverse participants to senders
      // old data
      // {
      //   "id": "t_2736861516333783",
      //   "senders": {
      //       "data": [
      //           {
      //               "name": "Posathorn Atiyutpakpol",
      //               "email": "2117825224990565@facebook.com",
      //               "id": "2117825224990565"
      //           },
      //           {
      //               "name": "Whale Whale",
      //               "email": "115849176470304@facebook.com",
      //               "id": "115849176470304"
      //           }
      //       ]
      //   }
      // }
      const { result } = await ChatProvider.findConversation({
        pageId,
        customerId
      })
      if (result) {
        if (this.platform === 'instagram' && result.participants) {
          result.senders = {
            data: result.participants.data.map(({ username, userId, id }) => ({
              id,
              userId,
              name: username,
              email: ''
            })).reverse()
          }
        }
        this.setCustomer({
          id: customerId,
          threadId: result.id,
          instagramUserId: result.senders?.data[0].userId,
          name: result.senders?.data[0].name,
          updatedTime: this.$dayjs().format(),
          to: `/messages/${customerId}`,
          cursors: null
        })
        ChatProvider.getShipnityInfo({ pageId }, {
          chats: [result.id]
        }).then((res) => {
          if (res.result[0]) {
            // remark: result ข้อมูลอาจมาไม่ครบ
            this.setCustomer({
              id: customerId,
              tags: res.result[0].tags,
              tagsWithColor: res.result[0].tagsWithColor,
              rewardLink: res.result[0].rewardLink,
              orderStatus: res.result[0].orderStatus,
              optedIn: res.result[0].optedIn
              // orderBank: res.result[0].orderBank,
              // paymentAccepted: res.result[0].paymentAccepted
            })
          }
        })
      }
    }
  }
}
</script>

<style>
  .chat-list-wrap {
    width: 385px;
    padding: 20px 12px 12px 12px;
  }
  .chat-main-wrap {
    width: 100%;
  }
</style>

<style scoped>
  .chat-list {
    padding: 20px 12px 12px 12px;
  }
  .chat-list-wrap {
    height: calc(100vh - 52px);
  }
  .chat-empty  {
    background-color: #f5f5f5;
    height: calc(100vh - 52px);
  }
  .chat-empty-text {
    margin: 12px 0;
    color: #c4c4c4;
  }
</style>
