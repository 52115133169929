<template>
  <v-select
    :value="page.platformKey"
    :items="items"
    :disabled="isChatLoading"
    :menu-props="{
      'origin': 'center center',
      'transition':'slide-y-transition',
      'offset-y': true,
    }"
    class="page-selection"
    color="primary"
    item-text="name"
    item-value="platformKey"
    hide-details
    outlined
    @change="onChange($event)">
    <template v-slot:selection="{ item }">
      <div class="page-title d-flex gap-3 align-center">
        <div class="p-relative">
          <v-avatar size="42">
            <img
              class="elevation-6"
              :src="`https://graph.facebook.com/${item.uid}/picture?type=small&access_token=${item.accessToken}`"
              :alt="item.name">
          </v-avatar>
          <v-icon
            class="avatar-mini-icon"
            size="20"
            :color="item.isInstagram ? '#c33fca' : '#2c82ef'">
            {{ item.isInstagram ? 'mdi-instagram' : 'mdi-facebook-messenger' }}
          </v-icon>
        </div>
        <div class="page-title-text">
          <span>{{ item.name }}</span>
        </div>
      </div>
    </template>
    <template v-slot:item="{ item }">
      <div class="page-title d-flex gap-3 align-center">
        <div class="p-relative">
          <v-avatar size="42">
            <img
              class="elevation-6"
              :src="`https://graph.facebook.com/${item.uid}/picture?type=small&access_token=${item.accessToken}`"
              :alt="item.name">
          </v-avatar>
          <v-icon
            class="avatar-mini-icon"
            size="20"
            :color="item.isInstagram ? '#c33fca' : '#2c82ef'">
            {{ item.isInstagram ? 'mdi-instagram' : 'mdi-facebook-messenger' }}
          </v-icon>
        </div>
        <div class="page-title-text">
          <span>{{ item.name }}</span>
        </div>
      </div>
    </template>
  </v-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PageList',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      page: 'Chat/page',
      pageId: 'Chat/pageId',
      stageId: 'Chat/stageId',
      customers: 'Chat/customers',
      customerList: 'Chat/customerList',
      isChatLoading: 'Chat/isChatLoading'
    })
  },
  created () {
    this.subscribe(this.page.subscribeId)
  },
  methods: {
    ...mapActions({
      setDefault: 'Chat/setDefault',
      setPage: 'Chat/setPage'
    }),
    onChange (platformKey) {
      this.unsubscribe()
      this.setDefault()
      const page = this.items.find((item) => item.platformKey === platformKey)
      this.setPage(page)
      // change: goto home every changes
      this.$router.push({ name: 'Inbox' })
      // if (this.$route.name === 'Messages') {
      //   this.$router.push({ name: 'Inbox' })
      // }
      this.subscribe(page.subscribeId)
    },
    subscribe (subscribeId) {
      // changes: subscribe cable by pageId and platform
      this.$cable.subscribe({
        channel: 'ConversationChannel',
        page: subscribeId
      })
      this.$cable.subscribe({
        channel: 'ChatRoomChannel',
        page: subscribeId
      })
    },
    unsubscribe () {
      this.$cable.unsubscribe('ConversationChannel')
      this.$cable.unsubscribe('ChatRoomChannel')
    }
  }
}

</script>

<style>
  .page-selection .v-select__selections {
    color: #1ba7e1;
  }
  .page-selection.v-text-field--outlined fieldset {
    border-color: #1ba7e1;
  }
  .page-selection .theme--light.v-icon {
    color: #1ba7e1;
  }
</style>
<style scoped>
  .page-title {
    width: 99%;
  }
  .page-title-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .avatar-mini-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    right: -6px;
    bottom: 0px;
  }
</style>
