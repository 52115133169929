<template>
  <div class="user-avatar-badge">
    <v-list-item
      :to="item.to"
      class="px-0"
      link
      exact
      dense>
      <v-badge
        :value="dots && !hideBadge"
        bordered
        top
        color="#ff7a1a"
        dot
        offset-x="10"
        offset-y="10">
        <div
          :class="{ 'user-avatar-badge__frame--optin': item.optedIn }"
          class="user-avatar-badge__frame">
          <v-avatar
            v-if="accessToken"
            :alt="item.name"
            class="d-block text-center mx-auto"
            color="grey darken-1"
            size="40">
            <v-img
              :src="url"
              lazy-src="/imgs/avatar.png"
              class="elevation-6"
              @error="getFallback()" />
          </v-avatar>
        </div>
      </v-badge>
    </v-list-item>
    <v-chip
      v-if="!dots"
      :class="{ 'd-none': hideBadge }"
      class="count-msg"
      color="#ff7a1a"
      text-color="white"
      x-small>
      ใหม่
    </v-chip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import avatar from '@/assets/imgs/avatar.png'
import ChatProvider from '@/resources/ChatProvider'

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    accessToken: {
      type: String,
      default: ''
    },
    dots: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      url: null
    }
  },
  computed: {
    ...mapGetters({
      page: 'Chat/page',
      pageId: 'Chat/pageId',
      platform: 'Chat/platform'
    }),
    hideBadge () {
      const isHide = !this.item.unreadCount || Number(this.item.id) === Number(this.$route.params.customerId)
      return isHide
    }
  },
  watch: {
    platform: {
      immediate: true,
      handler (platform) {
        const customerId = this.item.id
        // const username = this.item.name
        const accessToken = this.accessToken
        if (platform === 'instagram') {
          this.url = avatar
          // this.getAvatar(username)
          //   .then((res) => {
          //     this.url = res ? res.result : avatar
          //   })
        } else if (platform === 'messenger') {
          this.url = `https://graph.facebook.com/${customerId}/picture?type=small&access_token=${accessToken}`
        }
      }
    }
  },
  methods: {
    getAvatar (username) {
      try {
        return ChatProvider.getInstagramAvatar({ pageId: this.pageId, username })
      } catch {
        return null
      }
    },
    getFallback () {
      this.url = avatar
    }
  }
}
</script>

<style>
  .user-avatar-badge {
    position: relative;
    padding: 4px 9px;
  }
  .count-msg {
    position: absolute;
    top: -8px;
    right: -15px;
    padding: 8px 10px;
    z-index: 1;
  }
  .user-avatar-badge .v-list-item {
    justify-content: center;
  }
  .user-avatar-badge .v-list-item--link:before {
    background-color: transparent;
  }
</style>
